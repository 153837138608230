<template>
<v-container fluid>
  <v-card v-if="isReady">
    <v-data-table
      :headers="headers"
      :items="filteredItems"
    >
      <template v-slot:top>
        <v-toolbar
          dark
          color="blue darken-3"
          class="mb-1"
        >
          <v-text-field
            v-model="searchDebounced"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-text-field>

          <div style="max-width: 250px">
            <v-select
              class="pl-2"
              :items="filterSettings"
              item-text="name"
              item-value="value"
              v-model="currentSetting"
              outlined
              hide-details
              height="48"
              dense
            />
          </div>

        </v-toolbar>
      </template>

      <template v-slot:item.title="{ item }">
        <div v-html="item.title" />
      </template>

      <template v-slot:item.metadata.status="{ item }">
        <div v-html="item.metadata.status" />
      </template>

      <template v-slot:item.fields.author="{ item }">
        <div v-html="item.fields.author" />
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn text color="green" @click="goToPublicationDetails(item)">SHOW</v-btn>
      </template>
    </v-data-table>
  </v-card>
</v-container>

</template>

<script>
import {mapGetters} from "vuex";
import Fuse from 'fuse.js'
import _ from "lodash";
import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";

export default {
  name: "Publications",
  data() {
    return {
      itemsPerPageArray: [4, 8, 12],
      filter: {
        search: ''
      },
      showOnlyVerified: false,
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: 'name',
      unitItems: [],
      unitFilter: '',
      headers: [
        { text: 'Status', value: 'metadata.status' },
        { text: 'Name', value: 'title' },
        { text: 'Authors', value: 'fields.author' },
        { text: 'Actions', value: 'actions' }
      ],
    filterSettings: [
      {name: 'Show All', value: 'all'},
      {name: 'Show Verified', value: 'verified'},
      {name: 'Show Unverified', value: 'unverified'},
      {name: 'Show marked FPR', value: 'fpr'},
      {name: 'Show not marked FPR', value: 'not-fpr'},
      {name: 'Unverified, marked FPR', value: 'unverified-fpr'}
    ],
    currentSetting: 'all',
    fuse: null,
      fuseOptions: {
        threshold: 0.5,
        keys: [ 'metadata.status', 'title', 'fields.author' ],
        includeMatches: true,
        minMatchCharLength: 2
      },
      publications: [],
    }
  },
  created() {
    this.fuse = new Fuse([], this.fuseOptions);
  },
  mounted() {
    CLIENT.go(byApi('publications'))
      .addPaginationEnabledModifier(false)
      .get().then((result) => {
        this.publications = result.asPaginationResult().membersData();
    });
  },
  computed: {
    isReady() {
      return this.publications.length > 0;
    },
    searchDebounced: {
      get() {
        return this.filter.search;
      },
      set: _.debounce(function(newValue) {
        this.filter.search = newValue;
      }, 300)
    },
    filteredItems() {

      let currentPubs = this.publications;
      if(this.currentSetting === 'verified') {
        currentPubs = this.publications.filter(p => p['verified'] ?? false)
      }

      if(this.currentSetting === 'unverified') {
        currentPubs = this.publications.filter(p => !p['verified'] ?? true)
      }

      if(this.currentSetting === 'fpr') {
        currentPubs = this.publications.filter(p => p['includedInFpr'] ?? false);
      }

      if(this.currentSetting === 'not-fpr') {
        currentPubs = this.publications.filter(p => !p['includedInFpr'] ?? true);
      }

      if(this.currentSetting === 'unverified-fpr') {
        currentPubs = this.publications.filter(p => !p['verified'] ?? true)
        currentPubs = currentPubs.filter(p => p['includedInFpr'] ?? false);
      }

      if(this.filter.search === '') {
        return currentPubs;
      }

      this.fuse.setCollection(currentPubs);
      const fuseResult = this.fuse.search(this.filter.search);
      return this.highlight(fuseResult);
    }
  },
  methods: {
    goToPublicationDetails(publication) {
      this.$router.push({ name: 'se2a-publication-detail', params: { publicationId: publication.id }})
    },
    highlight(fuseResult) {
      const set = (obj, path, value) => {
        const pathValue = path.split('.');
        let i;

        for (i = 0; i < pathValue.length - 1; i++) {
          obj = obj[pathValue[i]];
        }

        obj[pathValue[i]] = value;
      };

      const generateHighlightedText = (inputText, regions = []) => {
        let content = '';
        let nextUnhighlightedRegionStartingIndex = 0;

        regions.forEach(region => {
          const lastRegionNextIndex = region[1] + 1;

          content += [
            inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
            `<span class="highlight-search-result">`,
            inputText.substring(region[0], lastRegionNextIndex),
            '</span>',
          ].join('');

          nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;
        });

        content += inputText.substring(nextUnhighlightedRegionStartingIndex);

        return content;
      };

      return fuseResult
        .filter(({ matches }) => matches && matches.length)
        .map(({ item, matches }) => {
          const highlightedItem = _.cloneDeep(item);

          matches.forEach((match) => {
            set(highlightedItem, match.key, generateHighlightedText(match.value, match.indices));
          });

          return highlightedItem;
        });
    }
  }
}
</script>

<style>
.highlight-search-result {
 background-color: rgba(255, 242, 0, 0.3);
}
</style>
